.page-container-launch-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  color: #e0e0e0;
  background-color: rgb(31, 41, 55); /* Match the site's background color */
  padding: 20px; /* Add some padding */
  box-sizing: border-box;
}

.centered-image {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 40px; /* Space between image and table */
  width: 100%; /* Ensure it takes the full width */
}

.centered-image img {
  max-width: 100%;
  max-height: 60vh; /* Prevent image from taking full view height */
  height: auto;
  display: block;
  border-radius: 8px; /* Add some rounding to match modern aesthetics */
}

.caption-bar {
  position: absolute;
  bottom: -20px; /* Adjust as needed to achieve the 1/3rd out effect */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  padding: 5px 10px; /* Reduce padding to decrease bar height */
  box-sizing: border-box;
  font-size: 2.5rem; /* Increase font size */
  line-height: 1; /* Adjust line height for better readability */
  display: inline-block; /* Allow the bar to shrink and wrap */
  max-width: 90%; /* Limit the width to 90% of the available space */
  width: max-content;
}

.caption-bar h2 {
  font-weight: bold; /* Apply bold font weight */
  margin: 0; /* Remove default margin */
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #333;
}

th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 2px solid #ffffff;
}

th {
  background-color: rgb(31, 41, 55);
  color: white;
}

td {
  background-color: rgb(31, 41, 55); /* Match the site's background color */
}

h6 {
  font-size: 12px; /* Increase the font size */
  display: flex;
  align-items: center; /* Center vertically */
  margin: 0; /* Remove default margin */
  color: #e0e0e0; /* Match the site's text color */
}

h5 {
  font-size: 1.25rem; /* Adjust font size for readability */
  color: #e0e0e0; /* Match the site's text color */
  margin: 10px 0; /* Add some margin for spacing */
  text-align: center; /* Center the text */
  line-height: 1.5; /* Improve line height for readability */
  font-weight: bold; /* Make the text bold */
}

@media (max-width: 850px) {
  .caption-bar {
    font-size: 1.5em;
  }

  .page-container {
    padding: 10px;
  }

  table {
    margin-top: 10px;
  }

  .page-container-launch-page {
    padding: 0;
  }
  h5 {
    font-size: 1rem; /* Decrease font size on smaller screens */
  }
}
