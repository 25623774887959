/* LaunchCard.css */

.launch-link {
    text-decoration: none;
    color: inherit;
  }
  
.launch-card {
    display: flex;
    width: 100%;
    margin: 10px 0;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
}

.launch-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.launch-image {
    flex: 1;
    height: auto;
    background-size: cover;
    background-position: center;
}

.launch-info {
    flex: 2;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #2e2e4e; /* Darker background for info section */
    color: #e0e0e0; /* Light text color */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.launch-info h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #b0b0c0; /* Accent color for headings */
    font-weight: bold;
}

.launch-info p {
    margin: 5px 0;
    color: #b0b0c0; /* Slightly lighter text color for better readability */
}

.launch-width {
  width: 80%;
  padding: 0;
}

/* Statcard CSS */

.stat-cards-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 8px 0px;
  width: 80%;
}

.stat-card {
  flex: 1 1 calc(33.333% - 32px);
  min-width: 250px;
  border: 1px solid #333; /* Or use any border color of your choice */
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  background-color: #2e2e4e; /* Dark background color */
  color: #e0e0e0; /* Light text color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.stat-title {
  font-size: 1rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.stage-container {
  width: 100%;
  margin: 10px 0;
}

.stage-and-recovery-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.stage-card, .landing-zone-card {
  display: flex;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-top: 12px;
}

.stage-card:hover, .landing-zone-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.landing-zone-image {
  flex: 1;
  height: 300px; /* Set height for the images */
  background-size: cover;
  background-position: center;
}

.stage-image {
  flex: 1;
  height: auto;
  background-size: cover;
  background-position: center;
}

.stage-info,
.landing-zone-info {
  flex: 2;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #2e2e4e; /* Darker background for info section */
  color: #e0e0e0; /* Light text color */
}

.stage-info h2,
.landing-zone-info h2 {
  font-size: 2rem;
  color: #b0b0c0; /* Accent color for headings */
  font-weight: bold;
}

.stage-info p,
.stage-info b,
.landing-zone-info p {
  margin: 5px 0;
  color: #b0b0c0; /* Slightly lighter text color for better readability */
}

.subheading-margin {
  margin-top: 10px;
  margin-bottom: 5px;
}
  
  @media (max-width: 768px) {
    .launch-image {
        display: none;
    }

    .launch-cards-container {
      width: 100%;
    }

    .launch-width {
      width: 100%;
      padding: 0 15px;
    }

    .stat-cards-container {
      width: 100%;
      padding: 0 15px;
    }

  }