/* LaunchList.css */

.page-container {
  display: flex;
  margin: 0 auto;
  color: #e0e0e0;
}

.left-container {
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 250px;
  top: 64px;
  height: 100%;
  background-color: rgb(31, 41, 55);
  padding: 10px;
  box-sizing: border-box;
}

.filter-container {
  position: sticky;
  top: 0;
  overflow-y: auto;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #4e4ecf;
  border-radius: 4px;
  background-color: #2e2e4e;
  color: #e0e0e0;
}

.search-button,
.clear-button {
  width: calc(50% - 2.5px); /* Each button takes up half the width with a bit of margin */
  padding: 10px;
  background-color: #4e4ecf;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover,
.clear-button:hover {
  background-color: #3e3e6e;
}

.launch-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  overflow-y: auto;
  max-height: 100%;
}

.launch-cards-container, .vehicle-launch-cards-container {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: sticky;
  top: 0;
  background-color: rgb(31, 41, 55);
}

.pagination-buttons-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.pagination-buttons {
  display: flex;
  gap: 5px;
}

.pagination-button {
  padding: 10px 15px;
  background-color: #4e4ecf;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button:hover {
  background-color: #3e3e6e;
}

.pagination-button:disabled {
  background-color: #2e2e4e;
  cursor: not-allowed;
}

.filter-toggle-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.filter-toggle-button svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}

@media (max-width: 768px) {
  .page-container {
    flex-direction: column;
  }

  .filter-container {
    position: relative;
  }

  .left-container {
    width: auto;
    height: auto;
    position: static;
    padding: 10px;
  }

  .launch-container {
    width: 100%;
  }

  .pagination-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 0;
    background-color: rgb(31, 41, 55);
    padding: 10px 20px;
  }

  .pagination-buttons-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .pagination-buttons {
    display: flex;
    gap: 5px;
  }

  .filter-toggle-button {
    display: block;
    background-color: #1f2937;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-left: auto; /* Move the filter icon to the right */
  }

  .launch-container {
    width: 100%;
  }

  .launch-cards-container {
    padding: 0px 5px;
  }

  .vehicle-launch-cards-container {
    padding: 0px 15px;
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust the height as needed */
}

.loading-indicator::before {
  content: "";
  width: 40px;
  height: 40px;
  border: 5px solid #4e4ecf;
  border-top: 5px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
