.chart-container {
  width: 100%;
  background-color: rgb(31, 41, 55);
  color: #e0e0e0;
  display: flex;
  justify-content: flex-start;
  overflow-x: hidden; /* Hide horizontal overflow */
}

.chart-inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow-y: hidden;
}

.chart {
  width: 100%;
  position: relative; 
  margin-right: -30px;
  margin-left: -5px;
  margin-bottom: -30px;
  margin-top: -40px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.controls {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.custom-legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
}

.stats {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.stats-group {
  display: flex;
  flex-direction: column;
}

.graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  color: #e0e0e0;
  background-color: rgb(31, 41, 55); /* Match the site's background color */
  box-sizing: border-box;
  padding: 20px;
  max-width: 77rem;
}

@media (max-width: 768px) {
  .graph-container {
    margin: 0;
    padding: 0;
  }

  .controls {
    flex-direction: column;
    padding: 20px;
    align-items: center;
    gap: 10px;
  }

  .stats {
    flex-direction: column;
    gap: 0;
  }

  .chart {
    padding-bottom: -60px;
  }
}
