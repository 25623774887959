/* Filter.css */

/* Styling for filter checkboxes and labels */
.family-label {
    margin-top: 0.5rem;
    display: block;
    font-weight: bold;
    color: #e0e0e0;
}

.subtypes-container {
    margin-left: 0.5rem; /* Adjusted margin for better alignment */
    width: 100%;
}

/* Show more/show less button */
.text-gray-600 {
    color: #9f9f9f;
    cursor: pointer;
    font-size: 0.875rem;
    transition: color 0.3s;
}

.text-gray-600:hover {
    color: #cccccc;
}

/* Checkbox styles */
input[type="checkbox"], .form-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    background-color: #2e2e4e;
    border-radius: 0.25rem;
    outline: none;
    cursor: pointer;
    margin-right: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

input[type="checkbox"]:checked, .form-checkbox:checked {
    background-color: #4e4ecf;
    border-color: currentColor;
}

input[type="checkbox"]:hover, .form-checkbox:hover {
    background-color: #3a3a6b;
    border-color: #3a3a6b;
}

input[type="checkbox"]:focus, .form-checkbox:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

/* Container for each filter */
.mb-2 {
    margin-bottom: 0.3rem;
}

.filter label {
    justify-content: left;
}

.label {
    justify-content: left;
    font-weight: bold;
}

.filter {
    display: block;
}

.family-container {
    width: 100%
}

/* Centering on mobile */
@media (max-width: 768px) {
    .subtypes-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: auto;
    }

    .family-label {
        text-align: center;
        padding-left: 8px;
    }

    .label {
        justify-content: center;
        text-align: center;
    }

    .filter.show {
        display: block;
    }
}
