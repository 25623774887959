.calendar-container {
  max-width: 80rem;
  margin: 0 auto;
  background-color: #1a1a2e; /* Darker background */
  color: #e0e0e0; /* Lighter text color */
}

.calendar-container button {
  background-color: #2e2e4e;
}

.calendar-container button:hover {
  background-color: #3e3e6e;
}

.calendar-container .selected {
  border: 2px solid #4e4ecf;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.month {
  font-weight: bold;
  font-size: 2rem;
}

.events-and-filter {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  height: auto;
}


/* Styling for table */

.stats-table-container {
  width: 100%;
  margin: 0 auto;
  background-color: rgb(31 41 55); /* Darker background to match the container */
  color: #e0e0e0; /* Lighter text color */
}

.stats-table {
  width: 100%;
  border-collapse: collapse;
}

.stats-table td {
  padding: 0.5rem 1rem;
}

.stats-table-label {
  font-weight: bold;
}

.stats-table-value {
  text-align: right;
}

.center {
  text-align: center;
}

.events-width {
  width: 100%
}


@media (min-width: 768px) {
  .dot {
    display: none;
  }

  .filter {
    width: 230px;
    justify-content: center;
  }

  .filter label {
    justify-content: left;
  }

  .family-container {
    align-items: flex-start; /* Align items to the left */
  }

  .family-label {
    position: static; /* Reset position */
    text-align: left; /* Align label text to the left on larger screens */
    padding-left: 0; /* Reset padding for larger screens */
  }

  .subtypes-container {
    align-items: flex-start; /* Align items to the left */
    margin-left: 1rem; /* Adjust as needed for proper indentation */
    left: 0%;
  }

  .subtype-label {
    justify-content: flex-start; /* Align items to the left */
    padding-left: 0.5rem; /* Reduce padding for larger screens */
    width: 100%;
  }

  .events-width {
    width: calc(100% - 240px);
  }

  .center {
    text-align: left;
    justify-content: center;
  }

  .events-and-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .stats-table-container {
    width: 80%;
  }
}
